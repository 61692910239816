import React, { useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import DashboardView from "./view";
import { PageContext } from "../../lib/context";
import {
  AGGREGATES,
  MERCHANT_WALLET_BALANCE,
  TOP_GROSSING_SCANNERS,
  TRANSACTIONS,
} from "../../lib/query";
import { useMutation, useQuery } from "@apollo/react-hooks";
import moment from 'moment'

const DashboardController = (props) => {
  const [gte, setGte] = useState(moment().subtract(2,'months').format('YYYY-MM-DD'))

  const { data, loading } = useQuery(MERCHANT_WALLET_BALANCE);

  const { data: top_gross_data, loading: top_gross_loading } = useQuery(
    TOP_GROSSING_SCANNERS
  );

  const {data: transaction_data, loading: transaction_loading} = useQuery(TRANSACTIONS,{
    variables: {
      gte
    }
  })

  const handleChangeDate = (value) => {
    switch (value) {
      case "daily":
        setGte(moment().format('YYYY-MM-DD'))
        break;
      case "weekly":
        setGte(moment().subtract(7,'days').format('YYYY-MM-DD'))
        break;
      case "monthly":
        setGte(moment().subtract(2,'months').format('YYYY-MM-DD'))
        break;
      default:
        break;
    }
  }

  const pageValues = {
    data,
    loading,
    top_gross_data,
    top_gross_loading,
    transaction_data,
    transaction_loading,
    handleChangeDate
  };

  return (
    <PageContext.Provider value={pageValues}>
      <DashboardView />
    </PageContext.Provider>
  );
};

export default DashboardController;
