import React, { useMemo, useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import DashboardView from "./view";
import { PageContext } from "../../lib/context";
import {
  AGGREGATES,
  MERCHANT_WALLET_BALANCE,
  TOP_GROSSING_SCANNERS,
  TRANSACTIONS,
} from "../../lib/query";
import { useMutation, useQuery } from "@apollo/react-hooks";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const TransactionsController = (props) => {
  const { id: billing_id } = useParams();
  const [gte, setGte] = useState(
    moment().subtract(2, "month").format("YYYY-MM-DD")
  );
  const [where, setWhere] = useState({
    created_at: {
      _gte: gte,
    },
  });
  const [csvData, setCsvData] = useState([
    [
      "Scanner Name",
      "Address",
      "Customer",
      "Amount",
      "Date",
      "Billing ID",
      "INVOICE NUMBER"
    ],
  ]);
  const [billingCsvData, setBillingCsvData] = useState([])
  const [billingCsvLoading, setBillingCsvLoading] = useState(false)
  const [pdfVisible, setPdfVisible] = useState(false)

  useEffect(()=>{
    if (billing_id) {
      console.log('bililng_id',billing_id)
      setWhere({
        billing_id: {
          _eq: billing_id
        }
      })
    }
  },[billing_id])

  const { data, loading } = useQuery(MERCHANT_WALLET_BALANCE);

  const { data: top_gross_data, loading: top_gross_loading } = useQuery(
    TOP_GROSSING_SCANNERS
  );

  const { data: transaction_data, loading: transaction_loading } = useQuery(
    TRANSACTIONS,
    {
      variables: {
        where,
      },
    }
  );

  useMemo(()=>{
    if (transaction_data) {
      if (transaction_data?.merchants_sales?.length > 0) {
        const data = []
        console.log('transaction data',transaction_data)
        transaction_data.merchants_sales.map((item, key)=>{
          const customer = item?.user?.first_name + " " + item?.user?.last_name
          let row = [
            item?.merchant?.name,
            item?.merchant?.address,
            customer,
            item?.amount,
            moment(item?.created_at).format('YYYY-MM-DD HH:mm a'),
            item?.merchants_billing?.id?.slice(0,8)??'Not Billed',
            item?.invoice_number
          ]
          data.push(row)
          if (key === transaction_data?.merchants_sales?.length - 1) {
            console.log('here')
            setCsvData([...csvData,...data])
          }
        })
      }
      setBillingCsvLoading(true)
      const data = []
      data.push(["Billing ID:",billing_id])
      data.push([""])
      data.push(["Merchant Name:",transaction_data?.merchants_sales?.[0]?.merchant?.name])
      data.push([""])
      data.push(["TRANSACTIONS LIST"])
      data.push(["ID","AMOUNT","DATE","CUSTOMER"])
      const sales_length = transaction_data?.merchants_sales?.length
      if (sales_length > 0) {
        for(var i=0;i<sales_length;i++) {
          let sale = transaction_data?.merchants_sales?.[i]
          data.push([
            sale?.id,
            sale?.amount,
            moment(sale?.created_at).format("YYYY-MMMM-DD"),
            sale?.user?.first_name+' '+sale?.user?.last_name
          ])
          if (i === sales_length-1){
            setBillingCsvData([...data])
            setBillingCsvLoading(false)
          }
        }
      } else {
        setBillingCsvLoading(false)
      }
    }
  },[transaction_data])

  const handleChangeDate = (value) => {
    switch (value) {
      case "daily":
        setGte(moment().format("YYYY-MM-DD"));
        break;
      case "weekly":
        setGte(moment().subtract(7, "days").format("YYYY-MM-DD"));
        break;
      case "monthly":
        setGte(moment().subtract(2, "months").format("YYYY-MM-DD"));
        break;
      default:
        break;
    }
  };

  const pageValues = {
    data,
    loading,
    top_gross_data,
    top_gross_loading,
    transaction_data,
    transaction_loading,
    handleChangeDate,
    billing_id,
    csvData,
    billingCsvLoading,
    billingCsvData,
    pdfVisible, 
    setPdfVisible
  };

  return (
    <PageContext.Provider value={pageValues}>
      <DashboardView />
    </PageContext.Provider>
  );
};

export default TransactionsController;
