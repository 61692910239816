import React from "react";
import moment from "moment";

export const transactions_table = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "username"
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address"
  },
];
