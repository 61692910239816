import React, { useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import DashboardView from "./view";
import { PageContext } from "../../lib/context";
import {
  BILLING,
} from "../../lib/query";
import { useMutation, useQuery } from "@apollo/react-hooks";
import moment from 'moment'
import bcrypt from 'bcryptjs'
import { ADD_BILLING, UPDATE_SALES_BILLING_ID } from "../../lib/mutations";

const BillingController = (props) => {

  const { data, loading } = useQuery(BILLING);

  const [addBilling, {loading: adding}] = useMutation(ADD_BILLING)
  const [updateSalesBillingId, {loading: updating}] = useMutation(UPDATE_SALES_BILLING_ID)

  const [form] = Form.useForm()

  const handleFinishForm = (data) => {
    const user_info = JSON.parse(localStorage.getItem("merchant_portal_user_info"));
    bcrypt.hash(data?.password, 8,async (err,hash)=>{
      try {
        let res = await addBilling({
          variables: {
            object: [
              {
                date_from: data?.date?.[0],
                date_to: data?.date?.[1],
                merchant_id: user_info?.id
              }
            ]
          }
        })
        const billing_id = res?.data?.insert_merchants_billing?.returning?.[0].id
        if (billing_id) {
          const updateRes = await updateSalesBillingId({
            variables: {
              billing_id,
              gte: moment(data?.date?.[0]).format('MM-DD-YYYY'),
              lte: moment(data?.date?.[1]).add(1,'day').format('MM-DD-YYYY')
            }
          })
          console.log(updateRes)
          if (updateRes?.data?.update_merchants_sales?.affected_rows >= 0) {
            notification.success({
              message: 'Successfully added billing'
            })
            setTimeout(() => {
              window.location.reload()
            }, 500);
          }
        }
      } catch (error) {
        notification.error({
          message: 'Failed to add scanner',
          description: error.message
        })
      }
    })
  }

  const pageValues = {
    data,
    loading,
    form,
    handleFinishForm,
    adding,
    updating
  };

  return (
    <PageContext.Provider value={pageValues}>
      <DashboardView />
    </PageContext.Provider>
  );
};

export default BillingController;
