import React from "react";
import moment from "moment";

export const transactions_table = [
  {
    title: "Name",
    render: (item) => <p>{item?.merchant?.name}</p>,
  },
  {
    title: "Address",
    render: (item) => <p>{item?.merchant?.address}</p>,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Date",
    render: (item) => <p>{moment(item?.created_at).format('YYYY-MM-DD HH:mm a')}</p>,
  },
];
