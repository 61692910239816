import React from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Form, Input, Button, Spin, Card } from "antd";

const RegistrationView = (props) => {
  const { form, handleFinishForm, loading } = useContext(PageContext);
  return (
    <div className="flex flex-row h-screen">
      <div
        className="flex flex-col h-full w-3/5 justify-center items-center"
        style={{
          backgroundImage: `url(${require("../../assets/login.jpg")})`,
          backgroundSize: "cover",
        }}
      >
        <p className="text-white text-5xl font-bold">WELCOME TO WEPAY</p>
        <p className="text-white text-xl">Your Digital Coupon Provider</p>
        <p className="text-white text-xl">Payment transactions made easy - for every Juan</p>
      </div>
      <div className="flex items-center justify-center w-2/5">
      <Card className="flex flex-row rounded-lg">
        <div className="flex flex-row">
          <img
            src={require("../../assets/wepay-square.png")}
            style={{ height: 50 }}
          />
          <div>
            <p className="font-bold text-base">WePay PH</p>
            <p className="font-bold text-base mb-5">Merchant Portal Login</p>
          </div>
        </div>
        <div>
          <Form layout="vertical" form={form} onFinish={handleFinishForm}>
            <Form.Item label="Username" name="email" required>
              <Input placeholder="Enter username" />
            </Form.Item>
            <Form.Item label="Password" name="password" required>
              <Input.Password placeholder="password" />
            </Form.Item>
            <Button
              className="w-full"
              disabled={loading}
              type="primary"
              htmlType="submit"
            >
              {loading ? <Spin /> : "Login"}
            </Button>
          </Form>
        </div>
      </Card>
      </div>
    </div>
  );
};

export default RegistrationView;
