import React, { useEffect, useState } from "react";
import "./App.css";
import Router from "./router";
import { AppContext } from "./lib/context";

function App() {
  const [APP_TOKEN, SET_APP_TOKEN] = useState(null);
  const [USER_INFO, SET_USER_INFO] = useState(null);

  useEffect(() => {
    let token = localStorage.getItem(process.env.REACT_APP_LS_TOKEN);
    SET_APP_TOKEN(token);
  }, []);

  const initialAppStates = {
    APP_TOKEN,
    SET_APP_TOKEN,
    USER_INFO,
    SET_USER_INFO
  };

  return (
    <div className="App">
      <AppContext.Provider value={initialAppStates}>
        <Router />
      </AppContext.Provider>
    </div>
  );
}

export default App;
