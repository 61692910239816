import React from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Form, Input, Button, Spin, Table, Select, Modal } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { WalletOutlined, RiseOutlined } from "@ant-design/icons";
import { transactions_table } from "./tables";
import { CSVLink, CSVDownload } from "react-csv";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";

const DashboardView = (props) => {
  const {
    data,
    loading,
    top_gross_data,
    top_gross_loading,
    transaction_data,
    transaction_loading,
    handleChangeDate,
    billing_id,
    csvData,
    billingCsvLoading,
    billingCsvData,
    pdfVisible,
    setPdfVisible,
  } = useContext(PageContext);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const user_info = JSON.parse(
      localStorage.getItem("merchant_portal_user_info")
    );
    setUserInfo(user_info);
  }, []);

  const renderTopGrossingScanners = () => {
    return top_gross_data?.merchants?.map((scanner, key) => (
      <div className="flex flex-row flex-wrap">
        <p className="text-blue-400 text-base">{`${scanner?.name} - ${scanner?.address} Php ${scanner?.merchants_sales_aggregate?.aggregate?.sum?.amount}`}</p>
      </div>
    ));
  };

  if (loading)
    return (
      <div className="flex h-full">
        <Spin className="self-center" />
      </div>
    );

  const renderTransactions = () => {
    if (!transaction_data?.merchants_sales?.length) return null;
    return transaction_data.merchants_sales.map((item, key) => (
      <View style={[styles.flex, styles.flexRow]}>
        <View style={[styles.transactionItem, { width: "5%" }]}>
          <Text style={{ fontSize: 8, fontWeight: "bold" }}>{key + 1}</Text>
        </View>
        <View style={[styles.transactionItem, { width: "30%" }]}>
          <Text style={{ fontSize: 8, fontWeight: "bold" }}>{item?.id}</Text>
        </View>
        <View style={[styles.transactionItem, { width: "15%" }]}>
          <Text style={{ fontSize: 8, fontWeight: "bold" }}>
            {moment(item?.created_at).format("YYYY-MMM-DD")}
          </Text>
        </View>
        <View style={[styles.transactionItem, { width: "20%" }]}>
          <Text style={{ fontSize: 8, fontWeight: "bold" }}>
            {item?.user?.first_name} {item?.user?.last_name}
          </Text>
        </View>
        <View style={[styles.transactionItem, { width: "15%" }]}>
          <Text style={{ fontSize: 8, fontWeight: "bold" }}>
            {item?.invoice_number}
          </Text>
        </View>
        <View style={[styles.transactionItem, { width: "15%" }]}>
          <Text style={{ fontSize: 8, fontWeight: "bold" }}>
            {new Intl.NumberFormat("php", {
              currency: "php",
              style: "currency",
            }).format(item?.amount)}
          </Text>
        </View>
      </View>
    ));
  };

  const renderTotal = () => {
    return (
      <View>
        <View
          style={[
            styles.flex,
            styles.flexRow,
            { justifyContent: "flex-end", marginTop: 10 },
          ]}
        >
          <View
            style={[
              styles.transactionItem,
              { width: "40%", backgroundColor: "#93C5FD" },
            ]}
          >
            <Text style={{ fontSize: 15, fontWeight: "bold" }}>
              TOTAL{" "}
              {new Intl.NumberFormat("php", {
                currency: "php",
                style: "currency",
              }).format(
                transaction_data?.merchants_sales_aggregate?.aggregate?.sum
                  ?.amount
              )}
            </Text>
          </View>
        </View>
        <View
          style={[
            styles.flex,
            styles.flexRow,
            { justifyContent: "flex-end", marginTop: 10 },
          ]}
        >
          <View
            style={{ borderColor: "black", borderBottom: 2, width: "40%" }}
          />
        </View>
        <View
          style={[
            styles.flex,
            styles.flexRow,
            { justifyContent: "flex-end", marginTop: 10 },
          ]}
        >
          <View
            style={{ borderColor: "black", borderBottom: 2, width: "40%" }}
          />
        </View>
        <View
          style={[
            styles.flex,
            styles.flexRow,
            { justifyContent: "flex-end", marginTop: 10 },
          ]}
        >
          <View
            style={{ borderColor: "black", borderBottom: 2, width: "40%" }}
          />
        </View>
        <View
          style={[
            styles.flex,
            styles.flexRow,
            {
              marginTop: 10,
              justifyContent: "space-between",
              marginHorizontal: 20,
            },
          ]}
        >
          <View
            style={{
              borderColor: "black",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 15,
              borderBottom: 2,
              width: "40%",
              backgroundColor: "#93C5FD",
            }}
          >
            <Text>MERCHANT</Text>
          </View>
          <View
            style={{
              borderColor: "black",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 15,
              borderBottom: 2,
              width: "40%",
              backgroundColor: "#93C5FD",
            }}
          >
            <Text>RECEIVED</Text>
          </View>
        </View>
        <View
          style={[
            styles.flex,
            styles.flexRow,
            {
              marginTop: 40,
              justifyContent: "space-between",
              marginHorizontal: 20,
            },
          ]}
        >
          <View style={{ width: "40%" }}>
            <Text style={{ fontSize: 12 }}>NAME SIGNATURE</Text>
          </View>
          <View style={{ width: "40%" }}>
            <Text style={{ fontSize: 12 }}>NAME SIGNATURE</Text>
          </View>
        </View>
        <View
          style={[
            styles.flex,
            styles.flexRow,
            {
              marginTop: 40,
              justifyContent: "space-between",
              marginHorizontal: 20,
            },
          ]}
        >
          <View style={{ width: "40%" }}>
            <Text style={{ fontSize: 12 }}>DATE</Text>
          </View>
          <View style={{ width: "40%" }}>
            <Text style={{ fontSize: 12 }}>DATE</Text>
          </View>
        </View>
      </View>
    );
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.main}>
        <View style={[styles.flex, styles.flexCol]}>
          <Image
            source={require("../../assets/SN2BANNER2.jpg")}
            style={{ width: "100%", objectFit: "contain" }}
          />
        </View>
        <View style={[styles.header]}>
          <View
            style={[
              styles.headerItem,
              { width: "30%", backgroundColor: "#93C5FD" },
            ]}
          >
            <Text>Billing ID</Text>
          </View>
          <View style={[styles.headerItem, { width: "70%" }]}>
            <Text>{billing_id}</Text>
          </View>
        </View>
        <View style={[styles.header]}>
          <View
            style={[
              styles.headerItem,
              { width: "30%", backgroundColor: "#93C5FD" },
            ]}
          >
            <Text>Merchant</Text>
          </View>
          <View style={[styles.headerItem, { width: "70%" }]}>
            <Text>{userInfo?.name}</Text>
          </View>
        </View>
        <View style={[styles.header]}>
          <View
            style={[
              styles.headerItem,
              { width: "100%", backgroundColor: "#93C5FD" },
            ]}
          >
            <Text>Transactions List</Text>
          </View>
        </View>
        <View style={[styles.flex, styles.flexRow]}>
          <View
            style={[
              styles.transactionItem,
              { width: "5%", backgroundColor: "#93C5FD" },
            ]}
          >
            <Text style={{ fontSize: 8, fontWeight: "bold" }}>NO</Text>
          </View>
          <View
            style={[
              styles.transactionItem,
              { width: "30%", backgroundColor: "#93C5FD" },
            ]}
          >
            <Text style={{ fontSize: 8, fontWeight: "bold" }}>ID</Text>
          </View>
          <View
            style={[
              styles.transactionItem,
              { width: "15%", backgroundColor: "#93C5FD" },
            ]}
          >
            <Text style={{ fontSize: 8, fontWeight: "bold" }}>DATE</Text>
          </View>
          <View
            style={[
              styles.transactionItem,
              { width: "20%", backgroundColor: "#93C5FD" },
            ]}
          >
            <Text style={{ fontSize: 8, fontWeight: "bold" }}>CUSTOMER</Text>
          </View>
          <View
            style={[
              styles.transactionItem,
              { width: "15%", backgroundColor: "#93C5FD" },
            ]}
          >
            <Text style={{ fontSize: 8, fontWeight: "bold" }}>INVOICE NO.</Text>
          </View>
          <View
            style={[
              styles.transactionItem,
              { width: "15%", backgroundColor: "#93C5FD" },
            ]}
          >
            <Text style={{ fontSize: 8, fontWeight: "bold" }}>AMOUNT</Text>
          </View>
        </View>
        <View style={[styles.flex, styles.flexCol]}>
          {renderTransactions()}
        </View>
        {transaction_data?.merchants_sales?.length < 35 && renderTotal()}
      </Page>
      {transaction_data?.merchants_sales?.length > 35 && (
        <Page>{renderTotal()}</Page>
      )}
    </Document>
  );

  return (
    <div className="flex flex-col h-auto pt-20 px-20">
      <p className="text-4xl">Transaction Logs</p>
      <p className="mb-10">{userInfo?.name}</p>
      {billing_id && (
        <>
          <p className="mb-5 font-bold">BILLING ID: {billing_id}</p>
          <Table
            dataSource={transaction_data?.merchants_sales ?? []}
            columns={transactions_table}
          />
          <CSVLink data={billingCsvData}>
            <Button
              type="primary"
              className="mb-2"
              disabled={billingCsvLoading}
            >
              {billingCsvLoading ? <Spin /> : "Download biling CSV"}
            </Button>
          </CSVLink>
          <br />
          <Button
            type="primary"
            onClick={() => setPdfVisible(true)}
            className="w-1/6"
          >
            Download Billing PDF
          </Button>
        </>
      )}
      {!billing_id && (
        <div className="mt-10">
          <Select
            defaultValue="monthly"
            className="mb-2"
            onChange={handleChangeDate}
          >
            <Select.Option value="daily">Day</Select.Option>
            <Select.Option value="weekly">Week</Select.Option>
            <Select.Option value="monthly">Month</Select.Option>
          </Select>
          <Table
            dataSource={transaction_data?.merchants_sales ?? []}
            columns={transactions_table}
          />
          <CSVLink data={csvData}>
            <Button type="primary" className="mb-2" disabled={loading}>
              {loading ? <Spin /> : "Download Transactions CSV"}
            </Button>
          </CSVLink>
        </div>
      )}

      <Modal
        centered={true}
        width={"90%"}
        visible={pdfVisible}
        onCancel={() => window.location.reload()}
        title="Download PDF"
        okButtonProps={{ style: { display: "none" } }}
      >
        <PDFViewer style={{ width: "100%", height: 600 }}>
          <MyDocument />
        </PDFViewer>
        <PDFDownloadLink document={<MyDocument />} fileName="somename.pdf">
          {({ blob, url, loading, error }) =>
            loading ? (
              "Loading document..."
            ) : (
              <Button type="primary">Download now!</Button>
            )
          }
        </PDFDownloadLink>
      </Modal>
    </div>
  );
};

const styles = StyleSheet.create({
  main: {
    marginTop: 10,
    width: 500,
    height: "100%",
  },
  flex: {
    display: "flex",
  },
  flexCol: {
    flexDirection: "column",
  },
  flexRow: {
    flexDirection: "row",
  },
  header: {
    // flex flex-row mt-1
    display: "flex",
    flexDirection: "row",
    marginTop: 2,
    width: "100%",
  },
  headerItem: {
    // w-2/5 items-center justify-center flex border-black border-2 bg-blue-200 text-lg font-bold
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    border: 2,
    borderColor: "black",
    fontSize: 18,
    fontWeight: "bold",
  },
  transactionItem: {
    // w-1/6 bg-blue-200 flex justify-center items-center font-bold border border-black
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    border: 1,
    borderColor: "black",
  },
});
export default DashboardView;
