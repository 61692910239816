import React from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Form, Input, Button, Spin, Table, Select, DatePicker } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { WalletOutlined, RiseOutlined } from "@ant-design/icons";
import { transactions_table } from "./tables";
import moment from "moment";

const DashboardView = (props) => {
  const {
    data,
    loading,
    form,
    handleFinishForm,
    adding,
    updating,
  } = useContext(PageContext);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const user_info = JSON.parse(
      localStorage.getItem("merchant_portal_user_info")
    );
    setUserInfo(user_info);
  }, []);

  if (loading)
    return (
      <div className="flex h-full">
        <Spin className="self-center" />
      </div>
    );

  return (
    <div className="flex flex-col h-auto pt-20 px-20">
      <p className="text-4xl">Billing</p>
      <p className="mb-10">{userInfo?.name}</p>
      <div className="flex flex-row"></div>
      <div className="mt-10">
        <p className="text-2xl">Billing List</p>
        <Table
          dataSource={data?.merchants_billing ?? []}
          columns={transactions_table}
        />
      </div>
      <div className="mt-10">
        <p className="text-2xl">Add Billing</p>
        <Form form={form} layout="vertical" onFinish={handleFinishForm}>
          <Form.Item
            required
            rules={[{ required: true }]}
            className="w-3/5"
            label="Date from - Date to"
            name="date"
          >
            <DatePicker.RangePicker disabledDate={(date) => date >= moment().subtract(1,'day')} />
          </Form.Item>

          <Button type="primary" htmlType="submit" disabled={updating || adding}>
            {(updating || adding) ? <Spin/> : "Add" }
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default DashboardView;
