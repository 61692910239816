import React from "react";
import moment from "moment";
import Item from "antd/lib/list/Item";

export const transactions_table = [
  {
    title: "ID",
    render: (item) => (
      <a href={`/transactions/${item?.id}`}>{item?.id?.slice(0,8)}</a>
    )
  },
  {
    title: "Date from",
    render: (item) => (
      <p>{moment(item?.date_from).format('YYYY-MM-DD')}</p>
    )
  },
  {
    title: "Date to",
    render: (item) => (
      <p>{moment(item?.date_to).format('YYYY-MM-DD')}</p>
    )
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status"
  },
  {
    title: "Total Amount",
    render: (item) => (
      <p>{new Intl.NumberFormat("en-US",{
        currency: "php",
        style: "currency",
      }).format(item?.merchants_sales_aggregate?.aggregate?.sum?.amount)}</p>
    )
  },
];
