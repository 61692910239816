import React, { useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import RegistrationView from "./view";
import { PageContext } from "../../lib/context";
import { UPSERT_USERS, UPSERT_USER_BUSINESS_CERTIFICATES, UPSERT_USER_ITEMS, LOGIN } from "../../lib/mutations";
import {useMutation} from '@apollo/react-hooks'

const Registration = (props) => {
  const [form] = Form.useForm();

  const [login, {loading}] = useMutation(LOGIN)

  const handleFinishForm = async (data) => {
    const res = await login({
      variables: {
        object: {
          ...data,
          type: "portal"
        }
      }
    })
    const login_data = res?.data?.merchant_portal_login
    if (login_data?.code == 200) {
      localStorage.setItem(process.env.REACT_APP_LS_TOKEN,login_data?.token)
      window.location.href = "/dashboard"
    } else {
      notification.error({
        message: "Login failed",
        description: "Incorrect username or password"
      })
    }
  }

  const pageValues = {
    form,
    handleFinishForm,
    loading
  }

  return (
    <PageContext.Provider value={pageValues}>
      <RegistrationView/>
    </PageContext.Provider>
  );
};

export default Registration;
