import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { authGuard, AuthGuard, DashGuard } from "./modules";
import { ApolloProvider } from "@apollo/react-hooks";
import { AppContext } from "../lib/context";
import makeApolloClient from "../lib/apollo";
import login from "../pages/login";
import DashboardController from "../pages/dashboard";
import ScannersController from "../pages/scanners";
import TransactionsController from "../pages/transactions";
import BillingController from "../pages/billing";

export default function App() {
  const [client, setClient] = useState(null);

  const { TOKEN } = useContext(AppContext);

  const initClient = async () => {
    let client
    const token = localStorage.getItem(process.env.REACT_APP_LS_TOKEN)
    if (token) {
      client = makeApolloClient(token);
    } else {
      client = makeApolloClient(process.env.REACT_APP_DEFAULT_JWT);
    }
    setClient(client);
  };

  useEffect(() => {
    initClient();
  }, []);

  if (!client) return null

  return (
    <Router>
      <ApolloProvider client={client}>
        <Switch>
          <AuthGuard exact path="/" component={login}/>
          <DashGuard exact path="/dashboard" component={DashboardController}/>
          <DashGuard exact path="/scanners" component={ScannersController}/>
          <DashGuard exact path="/transactions" component={TransactionsController}/>
          <DashGuard exact path="/transactions/:id" component={TransactionsController}/>
          <DashGuard exact path="/billing" component={BillingController}/>
        </Switch>
      </ApolloProvider>
    </Router>
  );
}
