import React from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Form, Input, Button, Spin, Table, Select } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { WalletOutlined, RiseOutlined } from "@ant-design/icons";
import { transactions_table } from "./tables";

const DashboardView = (props) => {
  const {
    data,
    loading,
    top_gross_data,
    top_gross_loading,
    transaction_data,
    transaction_loading,
    handleChangeDate
  } = useContext(PageContext);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const user_info = JSON.parse(localStorage.getItem("merchant_portal_user_info"));
    setUserInfo(user_info);
  }, []);

  const renderTopGrossingScanners = () => {
    return top_gross_data?.merchants?.map((scanner, key) => (
      <div className="flex flex-row flex-wrap">
        <p className="text-white text-base">{`${scanner?.name} - ${scanner?.address} Php ${scanner?.merchants_sales_aggregate?.aggregate?.sum?.amount}`}</p>
      </div>
    ));
  };

  if (loading)
    return (
      <div className="flex h-full">
        <Spin className="self-center" />
      </div>
    );

  return (
    <div className="flex flex-col h-auto pt-20 px-20">
      <p className="text-4xl">Merchant Dashboard</p>
      <p className="mb-10">{userInfo?.name}</p>
      <div className="flex flex-row">
        <div className="w-1/5 border shadow-lg rounded-lg px-5 py-5 mr-3 bg-blue-500 text-white">
          <div className="flex flex-row items-center">
            <WalletOutlined className="mr-2" />
            <p className="text-xl">Wallet balance</p>
          </div>
          <p className="text-white text-base">
            Php {data?.merchants_sales_aggregate?.aggregate?.sum?.amount}
          </p>
        </div>
        <div className="w-4/5 border shadow-lg rounded-lg px-5 py-5 bg-blue-500 text-white">
          <div className="flex flex-row items-center text-white">
            <RiseOutlined className="mr-2" />
            <p className="text-xl">Top Grossing Scanner Locations</p>
          </div>
          {renderTopGrossingScanners()}
        </div>
      </div>
      <div className="mt-10">
        <p className="text-2xl">Transactions</p>
        <Select defaultValue="monthly" className="mb-2" onChange={handleChangeDate}>
          <Select.Option value="daily">Day</Select.Option>
          <Select.Option value="weekly">Week</Select.Option>
          <Select.Option value="monthly">Month</Select.Option>
        </Select>
        <Table dataSource={transaction_data?.merchants_sales??[]} columns={transactions_table}/>
      </div>
    </div>
  );
};

export default DashboardView;
