import React, { useState } from "react";
import { Layout, Dropdown, Menu, Spin, SId } from "antd";
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  LogoutOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";
import { useSubscription, useQuery } from "@apollo/react-hooks";
import { USER_INFO } from "../lib/subscriptions";
import { useEffect } from "react";
import { formatMoney } from "../lib/util";
import { useContext } from "react";
import { AppContext } from "../lib/context";
import { USER_DATA } from "../lib/query";

const GuestLayout = ({ children }) => {
  const { Header, Content, Footer, Sider } = Layout;
  const { SubMenu } = Menu;
  const location = useLocation();
  const [userInfo, setUserInfo] = useState(null);
  const [userData, setUserData] = useState(null);

  const { SET_USER_INFO, USER_INFO: INFO } = useContext(AppContext);

  const { data, loading } = useSubscription(USER_INFO);

  const history = useHistory();

  const handleLink = (path) => {
    history.push(path);
  };

  const { data: user_data, loading: user_loading } = useQuery(USER_DATA);

  useEffect(() => {
    if (data) {
      setUserInfo(data?.users?.[0]);
      SET_USER_INFO(data?.users?.[0]);
      localStorage.setItem("userInfo", JSON.stringify(data?.users?.[0]));
    }
  }, [data]);

  useEffect(() => {
    if (user_data) {
      setUserData(user_data?.merchants?.[0]);
      localStorage.setItem(
        "merchant_portal_user_info",
        JSON.stringify(user_data?.merchants?.[0])
      );
    }
  }, [user_data]);

  const isCurrentPath = (path) => {
    if (path === location.pathname) return "text-yellow-400";
    else return "text-white";
  };

  const handleLogout = () => {
    localStorage.removeItem(process.env.REACT_APP_LS_TOKEN);
    window.location.href = "/";
  };

  const userMenu = (
    <Menu>
      <Menu.Item>
        <a rel="noopener noreferrer" onClick={handleLogout}>
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  const getSelectedKey = () => {
    switch (location.pathname) {
      case "/dashboard":
        return "1";
        break;
      case "/scanners":
        return "2";
        break;
      case "/transactions":
        return "3";
        break;
      case "/billing":
        return "4";
        break;
    }
  };

  return (
    <div className="flex flex-col h-auto">
      <Layout style={{ minHeight: "100vh" }}>
        <Sider theme="light" collapsible>
          <div className="logo flex flex-col items-center justify-center bg-white">
            <img
              src={require("../assets/wepay-square.png")}
              style={{ height: 100 }}
            />
            <p className="text-base font-bold">Merchant Portal</p>
          </div>
          <Menu theme="light" selectedKeys={getSelectedKey()} mode="inline">
            <Menu.Item
              key="1"
              icon={<UsergroupAddOutlined />}
              onClick={() => (window.location.href = "/dashboard")}
            >
              Dashboard
            </Menu.Item>
            <Menu.Item
              key="2"
              icon={<UsergroupAddOutlined />}
              onClick={() => (window.location.href = "/scanners")}
            >
              Scanners
            </Menu.Item>
            <Menu.Item
              key="3"
              icon={<UsergroupAddOutlined />}
              onClick={() => (window.location.href = "/transactions")}
            >
              Transaction Logs
            </Menu.Item>
            <Menu.Item
              key="4"
              icon={<UsergroupAddOutlined />}
              onClick={() => (window.location.href = "/billing")}
            >
              Billing
            </Menu.Item>
            <Menu.Item
              key="5"
              icon={<LogoutOutlined />}
              onClick={() => {
                localStorage.removeItem(process.env.REACT_APP_LS_TOKEN);
                setTimeout(() => {
                  window.location.href = "/";
                }, 1000);
              }}
            >
              Logout
            </Menu.Item>
          </Menu>
          <div className="flex flex-col mt-5 justify-center items-center text-xs text-white">
            <p>Username: {userData?.username}</p>
            <p>Merchant Name: {userData?.name}</p>
          </div>
          <div className="flex flex-row mt-20 justify-center items-center text-xs text-white">
            <p>Powered by WePay</p>
          </div>
        </Sider>
        <Layout className="site-layout ">
          <Content style={{ margin: "0 16px" }} className="bg-white">
            {children}
          </Content>
          <Footer style={{ textAlign: "center" }}>
            CPG Portal @2020 WePay PH
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default GuestLayout;
