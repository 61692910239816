import gql from "graphql-tag";

export const USER_REGISTRATIONS = gql`
query MyQuery($status: String) {
  users(where: { status: {_eq: $status}}) {
    id
    current_location
    email
    first_name
    gender
    last_name
    mobile_number
    new_business_name
    status
  }
}
`

export const USER_DETAILS = gql`
query MyQuery($id: uuid) {
  users(where: {id: {_eq: $id}}) {
    barangay
    birth_date
    building_apartment_name
    business_address
    business_certificates
    business_months_years
    cellphone_number
    chosen_business_reason
    chosen_business_will_be_successful_reason
    city_municipality
    civil_status
    classification
    current_location
    disadvantage_group
    email
    experiences
    family_count
    fb_messenger
    financial_support_name
    first_name
    gender
    has_business
    has_received_financial_support
    has_valid_id
    house_no
    id
    id_expiry
    id_number
    id_issuer
    is_business_registered
    is_lot_owned
    landline_number
    last_name
    mobile_number
    monthly_income
    name_of_spouse
    new_business_name
    number_of_employees
    pin
    place_of_birth
    reason_to_be_helped
    sitio_or_purok
    spouse_birth_date
    spouse_contact_number
    street_name
    subdivision
    type
    wallet_balance
    status
    estimated_daily_sales
    chosen_materials_and_where_to_get_them 
    packaging
    has_lot_available
    equipments_and_machinery
    people_to_hire 
    target_per_day_week 
    how_to_inspect_product_quality
    where_to_sell
    product_uniqueness 
    is_product_of_cebu_reason 
    category 
    user_picture 
    user_id_picture 
    industry 
    business_mobile 
    business_landline 
    business_website_social 
    business_rent 
    business_rent_owner 
    business_rent_address 
    business_rent_owner_number 
    receive_date 
    receive_amount
    receive_agency 
    receive_usage 
    application_purpose 
    total_monthly_income 
    total_monthly_product_sales 
    net_monthly_income 
    total_net_worth 
    monthly_rent_cost 
    other_expenses 
    other_expense_debt 
    business_kind 
    assigned_group
    id_type
    floor_no
    user_business_certificates {
      name
      user_id
    }
    user_items {
      id
      item
      cost
      quantity
      suggested_supplier_vendor
      unit_price
      user_id
    }
  }
}
`


export const USER_DATA = gql`
query MyQuery {
  merchants(where: {main_id: {_is_null: true}}) {
    id
    username
    name
  }
}
`

export const AGGREGATES = gql`
query MyQuery {
  total: users_aggregate {
    aggregate {
      count
    }
  }
  pending: users_aggregate(where: {status: {_eq: "pending"}}) {
    aggregate {
      count
    }
  }
  accepted: users_aggregate(where: {status: {_eq: "accept"}}) {
    aggregate {
      count
    }
  }
  screened: users_aggregate(where: {status: {_eq: "screened"}}) {
    aggregate {
      count
    }
  }
  approved: users_aggregate(where: {status: {_eq: "approved"}}) {
    aggregate {
      count
    }
  }
  denied: users_aggregate(where: {status: {_eq: "denied"}}) {
    aggregate {
      count
    }
  }
}
`


export const ADMIN_BM_ESTIMATES = gql`
query MyQuery {
  portal_users(where: {permission: {_eq: "bm"}}) {
    username
    accepted_allocation: group_users_aggregate(where:
      {status: {_eq: "accept"}}) {
      aggregate {
        count
        sum {
          wallet_balance
        }
      }
    }
    pending_allocation: group_users_aggregate(where:
      {status: {_eq: "pending"}}) {
      aggregate {
        count
      }
    }
  }
}
`

export const ADMIN_MERCHANTS = gql`
query MyQuery {
  merchants {
    name
    username
    merchants_sales_aggregate(where: {status: {_eq: "REIMBURSED"}}) {
      aggregate {
        count
        sum {
          amount
        }
      }
    }
  }
}
`

export const ADMIN_CPG_USERS = gql`
query MyQuery {
  users {
    first_name
    last_name
    wallet_balance
    merchants_sales_aggregate {
      aggregate {
        count
        sum {
          amount
        }
      }
    }
  }
}
`

export const MERCHANT_WALLET_BALANCE = gql`
query MyQuery {
  merchants_sales_aggregate(where: {status: {_eq: "AVAILABLE"}}) {
    aggregate {
      sum {
        amount
      }
    }
  }
}
`

export const TOP_GROSSING_SCANNERS = gql`
query MyQuery {
  merchants(order_by: {merchants_sales_aggregate: {sum: {amount: desc}}}, limit: 5) {
    name
    address
    merchants_sales_aggregate {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
}
`

export const TRANSACTIONS = gql`
query MyQuery($where: merchants_sales_bool_exp) {
  merchants_sales(where: $where) {
    id
    invoice_number
    merchant_id
    status
    user_id
    item
    created_at
    amount
    merchant {
      name
      username
      address
      main_merchant {
        name
			}
    }
    merchants_billing {
      id
    }
    user {
      first_name
      last_name
    }
  }

  merchants_sales_aggregate(where: $where) {
    aggregate {
      sum {
        amount
      }
    }
  }
}
`

export const SCANNERS = gql`
query MyQuery {
  merchants(where: {type: {_eq: "scanner"}}) {
    name
    address
    username
  }
}
`

export const BILLING = gql`
query MyQuery {
  merchants_billing(order_by: {created_at: desc}) {
    created_at
    date_from
    date_to
    id
    merchant_id
    status
    updated_at
    merchants_sales {
      invoice_number
      merchant_id
      status
      user_id
      item
      created_at
      amount
      merchant {
        name
        username
        address
      }
    }
    merchants_sales_aggregate {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
}
`