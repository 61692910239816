import React, { useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import DashboardView from "./view";
import { PageContext } from "../../lib/context";
import {
  AGGREGATES,
  MERCHANT_WALLET_BALANCE,
  SCANNERS,
  TOP_GROSSING_SCANNERS,
  TRANSACTIONS,
} from "../../lib/query";
import { useMutation, useQuery } from "@apollo/react-hooks";
import moment from 'moment'
import bcrypt from 'bcryptjs'
import { ADD_SCANNER } from "../../lib/mutations";

const ScannersController = (props) => {

  const { data, loading } = useQuery(SCANNERS);

  const [addScanner] = useMutation(ADD_SCANNER)

  const [form] = Form.useForm()

  const handleFinishForm = (data) => {
    const user_info = JSON.parse(localStorage.getItem("merchant_portal_user_info"));
    bcrypt.hash(data?.password, 8,async (err,hash)=>{
      try {
        let res = await addScanner({
          variables: {
            object: [
              {
                ...data,
                type: 'scanner',
                main_id: user_info?.id,
                password: hash,
              }
            ]
          }
        })
        if (res?.data?.insert_merchants?.affected_rows) {
          notification.success({
            message: 'Successfully added scanner'
          })
          setTimeout(() => {
            window.location.reload()
          }, 500);
        }
      } catch (error) {
        notification.error({
          message: 'Failed to add scanner',
          description: error.message
        })
      }
    })
  }

  const pageValues = {
    data,
    loading,
    form,
    handleFinishForm
  };

  return (
    <PageContext.Provider value={pageValues}>
      <DashboardView />
    </PageContext.Provider>
  );
};

export default ScannersController;
