import gql from "graphql-tag";

export const LOGIN = gql`
  mutation MyMutation($object: LoginInput!) {
    merchant_portal_login(object: $object) {
      code
      message
      token
    }
  }
`;

export const UPSERT_USERS = gql`
  mutation MyMutation($object: [users_insert_input!]!) {
    insert_users(objects: $object) {
      returning {
        id
      }
    }
  }
`;

export const UPSERT_USER_ITEMS = gql`
  mutation MyMutation($object: [user_items_insert_input!]!) {
    insert_user_items(objects: $object) {
      affected_rows
    }
  }
`;

export const UPSERT_USER_BUSINESS_CERTIFICATES = gql`
  mutation MyMutation($object: [user_business_certificates_insert_input!]!) {
    insert_user_business_certificates(objects: $object) {
      affected_rows
    }
  }
`;

export const UPDATE_USER = gql`
  mutation MyMutation($wallet_balance: Float, $status: String, $id: uuid) {
    update_users(
      where: { id: { _eq: $id } }
      _set: { wallet_balance: $wallet_balance, status: $status }
    ) {
      affected_rows
    }
  }
`;

export const ADD_SCANNER = gql`
  mutation MyMutation($object: [merchants_insert_input!]!) {
    insert_merchants(objects: $object) {
      affected_rows
    }
  }
`;

export const ADD_BILLING = gql`
  mutation MyMutation($object: [merchants_billing_insert_input!]!) {
    insert_merchants_billing(objects: $object) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const UPDATE_SALES_BILLING_ID = gql`
  mutation MyMutation($lte: timestamptz, $gte: timestamptz, $billing_id: uuid) {
    update_merchants_sales(
      where: {
        _and: [
          { created_at: { _lte: $lte } },
          { created_at: { _gte: $gte } },
          { status: {_eq: "AVAILABLE"}},
          { billing_id: {_is_null: true}}
        ]
      }
      _set: { billing_id: $billing_id }
    ) {
      affected_rows
    }
  }
`;
