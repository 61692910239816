import React from "react";
import moment from "moment";

export const transactions_table = [
  {
    title: "Scanner Name",
    render: (item) => <p>{item?.merchant?.name}</p>,
  },
  {
    title: "Address",
    render: (item) => <p>{item?.merchant?.address}</p>,
  },
  {
    title: "Customer",
    render: (item) => <p>{item?.user?.first_name} {item?.user?.last_name}</p>,
  },
  {
    title: "Invoice number",
    render: (item) => <p>{item?.invoice_number}</p>,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    sorter: {
      compare: (a, b) => a?.amount - b?.amount,
      multiple: 1,
    }
  },
  {
    title: "Date",
    render: (item) => <p>{moment(item?.created_at).format('YYYY-MM-DD HH:mm a')}</p>,
    sorter: {
      compare: (a, b) => a?.created_at?.localeCompare(b?.created_at),
      multiple: 2,
    }
  },
  {
    title: "Billing ID",
    render: (item) => <p>{item?.merchants_billing?.id?.slice(0,8)??'Not Billed'}</p>,
  },
];
